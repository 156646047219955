import { App } from "vue";
import DoctorEntityService from "@/services/DoctorEntityService";
import AppConfigService from "@/services/AppConfigService";

export const DoctorEntityServiceCtx = Symbol("DoctorEntityService");
export const AppConfigServiceCtx = Symbol("AppConfigService");

export default {
  install(app: App) {
    this.installDoctorEntityService(app);
    this.installAppConfigService(app);
  },

  installDoctorEntityService(app: App) {
    const doctorEntityService = new DoctorEntityService();
    app.provide(DoctorEntityServiceCtx, doctorEntityService);
    app.config.globalProperties.$doctorEntityService = doctorEntityService;
  },

  installAppConfigService(app: App) {
    const appConfigService = new AppConfigService();
    app.provide(AppConfigServiceCtx, appConfigService);
    app.config.globalProperties.$appConfigService = appConfigService;
  },
};
