export enum FileDefinition {
  CID = 1,
  ALP = 2,
  CMR = 4,
  DIF = 8,
  IBN = 16,
}

export const FileDefinitionInfo = {
  [FileDefinition.CID]: {
    name: "Carte de identitate",
    tooltip: "Cartea de identitate sau copia cartii de identitate",
  },
  [FileDefinition.ALP]: {
    name: "Aviz de libera practica",
    tooltip:
      "Copia avizului anual de libera practica sau copia adeverintei de la locul de munca cu dovada activitatii medicale in specialitatea detinută, nu mai veche de 30 de zile",
  },
  [FileDefinition.CMR]: {
    name: "Certificat Colegiul Medicilor",
    tooltip:
      "Certificatul de membru al Colegiului Medicilor din Romania, vizat la zi",
  },
  [FileDefinition.DIF]: {
    name: "Date de identificare fiscala",
    tooltip:
      "Datele de identificare fiscala ale entitatii comerciale (Persoana Fizica Independenta, SRL)",
  },
  [FileDefinition.IBN]: {
    name: "Cont bancar",
    tooltip: "Contul bancar (IBAN) pentru incasare pret consultatii.",
  },
};

export interface File {
  value: FileDefinition;
  info: string;
  present: boolean;
}

export class FileTypeSerializer {
  static deserialize(docSet: number): File[] {
    const fileTypes: File[] = [];
    for (const fileDefKey in FileDefinition) {
      if (!isNaN(Number(fileDefKey))) {
        continue;
      }
      const fileDefValue = FileDefinition[fileDefKey];
      if (typeof fileDefValue === "number") {
        fileTypes.push({
          value: fileDefValue,
          info: FileDefinitionInfo[fileDefValue],
          present: (docSet & fileDefValue) === fileDefValue,
        });
      }
    }
    return fileTypes;
  }
  static serialize(files: File[]): number {
    let docSet = 0;
    if (files != null && files.length > 0) {
      files.forEach((f) => {
        docSet |= f.value;
      });
    }
    return docSet;
  }
}
