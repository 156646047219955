export class DateUtils {
  static format = (datetime: string): string => {
    const date = new Date(datetime);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    //const hours = date.getHours().toString().padStart(2, '0');
    //const minutes = date.getMinutes().toString().padStart(2, '0');
    //const seconds = date.getSeconds().toString().padStart(2, '0');
    //return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
    return `${day}.${month}.${year}`;
  };
  static isInThePast = (datetime: string): boolean => {
    const date = new Date(datetime);
    const now = new Date();
    return date < now;
  };
}
