
import { Doctor } from '@/interfaces/Doctor';
import DoctorService from '@/services/DoctorService';
import Card from 'primevue/card';
import useModal from '@/stores/modal';
import DoctorInvite from '@/components/DoctorInvite.vue';
import DoctorList from '@/components/DoctorList.vue';
import GenericModal from '@/components/GenericModal.vue';
import { useToast } from 'primevue/usetoast';
import { useRoute, useRouter } from 'vue-router';
import Toast from 'primevue/toast';
import Button from 'primevue/button';
import { onMounted, ref, Ref, toRaw, computed } from 'vue';
import { DoctorConfig } from '@/interfaces/DoctorConfig';

export default {
  components: {
    DoctorList,
    GenericModal,
    Toast,
    Button
  },
  setup() {
    // Vars definitions
    const modal = useModal();
    const toast = useToast();
    const route = useRoute();
    const router = useRouter();
    const doctors = ref<Doctor[]>();
    const requestId = computed(() => route.query.requestId);

    // Functions definitions
    const fetchDoctors = async () => {
      try {
        doctors.value = await DoctorService.fetchDoctors();
      } catch (error) {
        console.error(error as string);
      }
    };

    const handleOnClickOpenModal = () => {
      modal.open('Invită un medic', DoctorInvite, [
        {
          icon: 'pi pi-times',
          label: 'Anulează',
          disabled: false,
          callback: () => {
            modal.close();
          }
        },
        {
          icon: 'pi pi-check',
          label: 'Salvează',
          disabled: true,
          callback: (model: Ref<Doctor>) => {
            saveDoctor(model.value);
          }
        }
      ]);
    };

    const saveDoctor = async (doctor: Doctor) => {
      try {
        if (!doctor.phoneNo.startsWith('+')) {
          doctor.phoneNo = '+4' + doctor.phoneNo;
        }
        const doctorId: string = (await DoctorService.saveDoctor(doctor)).id;
        if (doctor.config) {
          const resp: object = await DoctorService.saveDoctorConfig(
            doctorId,
            doctor?.config
          );
        }
        if (doctor.entity) {
          const resp: object = await DoctorService.saveDoctorEntity(
            doctorId,
            doctor?.entity
          );
        }
        showSuccess();
        doctor.documents &&
          saveDoctorDocuments(doctorId, toRaw(doctor.documents));
        if (requestId.value) {
          router.push({ name: 'doctors' });
        } else {
          location.reload();
        }
      } catch (err) {
        if (err instanceof Error) {
          showError(err.message);
        } else {
          showError('A aparut o eroare la salvarea utilizatorului.');
        }
      }
    };

    const saveDoctorConfig = async (doctorId: string, config: DoctorConfig) => {
      try {
        if (config) {
          const response = await DoctorService.saveDoctorConfig(
            doctorId,
            config
          );
        }
      } catch {
        showError('A aparut o eroare la salvarea decontarii.');
      }
    };

    const saveDoctorDocuments = async (
      doctorId: string,
      filesMap: Map<string, File>
    ) => {
      try {
        if (filesMap && filesMap.size > 0) {
          filesMap.forEach(async (file, docSetId) => {
            const response = await DoctorService.saveDoctorDocument(
              doctorId,
              docSetId,
              file
            );
          });
        }
      } catch {
        showError('A aparut o eroare la salvarea documentelor.');
      }
    };

    const showError = (message: string): void => {
      toast.add({
        severity: 'error',
        summary: 'A aparut o eroare!',
        detail: message,
        life: 3000
      });
      modal.refresh();
    };

    const showSuccess = (): void => {
      toast.add({
        severity: 'success',
        summary: 'Actiune realizata cu succes!',
        detail: 'Utilizatorul a fost adaugat cu succes',
        life: 3000
      });
      modal.close();
    };

    // Component actions
    onMounted(async () => {
      fetchDoctors();
      if (requestId.value) {
        handleOnClickOpenModal();
      }
    });

    return { doctors, modal, handleOnClickOpenModal, showError };
  }
};
