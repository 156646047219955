import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: $setup.isOpen,
    "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (($setup.isOpen) = $event)),
    maximizable: "",
    modal: "",
    header: $setup.modal.header,
    class: "lg:w-8 md:w-10 w-11"
  }, {
    footer: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.modal.actions, (action) => {
        return (_openBlock(), _createBlock(_component_Button, {
          key: action.label,
          onClick: ($event: any) => ($setup.triggerAction(action)),
          label: action.label,
          disabled: action.disabled,
          icon: action.icon,
          class: _normalizeClass(
          action.label !== 'Anulează' ? 'p-button-md' : 'p-button-secondary'
        ),
          outlined: action.label !== 'Anulează'
        }, null, 8, ["onClick", "label", "disabled", "icon", "class", "outlined"]))
      }), 128))
    ]),
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent($setup.view), {
        modelValue: $setup.model,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = (val) => ($setup.model.value = val))
      }, null, 8, ["modelValue"]))
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}