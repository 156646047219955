export class MoneyUtils {
  static formatd = (value: number | undefined): string => {
    return this.convert(value);
  };
  static format = (value: number | undefined, currency: string): string => {
    return this.convert(value) + " " + currency;
  };
  static convert = (value: number | undefined): string => {
    if (value) {
      return (value / 100).toFixed(2);
    }
    return "0";
  };
  static safeCurrency = (value: string | undefined): string => {
    if (value) return value;
    return "UDF";
  };
}
