export class FormValidator {
  static isPresent(val: string): boolean {
    return !!val;
  }

  static isPresentObj(val: object): boolean {
    return !!val;
  }

  static isValidEmail(val: string): boolean {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return regex.test(val);
  }

  static isValidPhone(val: string): boolean {
    const regex =
      /^(?:\+?(\d{1,3}))?[-. (]*(\d{1,4})?[-. )]*(\d{1,4})[-. ]*(\d{1,4})[-. ]*(\d{1,9})?$/;
    return regex.test(val) && val.length >= 10 && val.length <= 15;
  }
}
