import { AppContext } from "@/config/AppContext";

const keycloakInstance = AppContext.getKeycloak();

type CallbackOneParam<T1 = void, T2 = void> = () => T2;

const Login = (onAuthenticatedCallback: CallbackOneParam): void => {
  const activeToken = Token();
  const activeRefreshToken = RefreshToken();
  keycloakInstance
    .init({
      onLoad: "login-required",
      token: activeToken,
      refreshToken: activeRefreshToken,
    })
    .then(function (authenticated) {
      if (
        authenticated &&
        keycloakInstance.token &&
        keycloakInstance.refreshToken
      ) {
        localStorage.setItem("authToken", keycloakInstance.token);
        localStorage.setItem("refreshToken", keycloakInstance.refreshToken);
      }
      if (authenticated) {
        onAuthenticatedCallback();
      } else {
        localStorage.removeItem("authToken");
        localStorage.removeItem("refreshToken");
        window.location.reload;
      }
    })
    .catch((e) => {
      console.dir(e);
      console.log(`keycloak init exception: ${e}`);
    });
};

const CheckLogged = (): boolean | undefined => {
  if (keycloakInstance.token) {
    return !keycloakInstance.isTokenExpired();
  }
  //clearKeycloakCookies(); // in case the user is logged from another app
  return false;
};

const clearKeycloakCookies = (): void => {
  const cookies = document.cookie.split("; ");
  alert(document.cookie);
  for (const cookie of cookies) {
    alert(cookie);
    if (cookie.includes("KEYCLOAK_SESSION")) {
      const cookieName = cookie.split("=")[0];
      const domain = window.location.hostname;
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain}`;
    }
    if (cookie.includes("KEYCLOAK_SESSION_LEGACY")) {
      const cookieName = cookie.split("=")[0];
      const domain = window.location.hostname;
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain}`;
    }
  }
};

const CheckTokenValidity = (): boolean | undefined => {
  if (keycloakInstance.token) {
    try {
      const base64Url = keycloakInstance.token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
          .join(""),
      );
      const pay = JSON.parse(jsonPayload);
      const now = new Date();
      const expirationDate = new Date(pay.exp * 1000);
      const valid = pay.azp === "webadmin" && expirationDate > now;
      return valid;
    } catch (error) {
      return false;
    }
  }
  return false;
};

const UserName = (): string | undefined =>
  keycloakInstance?.tokenParsed?.preferred_username;

const Token = (): string | undefined => {
  const localToken = localStorage.getItem("authToken");
  return localToken || keycloakInstance?.token;
};

const RefreshToken = (): string | undefined => {
  const localRefreshToken = localStorage.getItem("refreshToken");
  return localRefreshToken || keycloakInstance?.refreshToken;
};

const LogOut = () => {
  keycloakInstance.logout();
  localStorage.removeItem("authToken");
  localStorage.removeItem("refreshToken");
};

const KeyCloakService = {
  CallLogin: Login,
  GetUserName: UserName,
  GetAccessToken: Token,
  GetRefreshToken: RefreshToken,
  CallLogOut: LogOut,
  IsLogged: CheckLogged,
  IsValidToken: CheckTokenValidity,
};

export default KeyCloakService;
