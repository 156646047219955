import { Doctor } from "@/interfaces/Doctor";

export function convertToDoctor(data: any): Doctor {
  const safePhone = data.phoneNo?.startsWith("inactive-")
    ? data.phoneNo.replace("inactive-", "")
    : data.phoneNo;
  const safeEmail = data.email?.startsWith("inactive-")
    ? data.email.replace("inactive-", "")
    : data.email;
  return {
    id: data.id,
    email: safeEmail,
    firstName: data.firstName,
    lastName: data.lastName,
    title: data.title,
    photoUrl: data.photoUrl || "",
    phoneNo: safePhone,
    deviceType: data.deviceType?.toUpperCase(),
    confirmed: data.confirmed,
    status: data.status,
    specialization: data.specialty,
    docSet: data.docSet,
    requestId: data.requestId,
  };
}

export function convertDoctor(data: Doctor) {
  const safePhone =
    !data.phoneNo?.startsWith("inactive-") && data.status == 0
      ? "inactive-" + data.phoneNo
      : data.phoneNo;
  const safeEmail =
    !data.email?.startsWith("inactive-") && data.status == 0
      ? "inactive-" + data.email
      : data.email;
  return {
    id: data.id,
    email: safeEmail,
    firstName: data.firstName,
    lastName: data.lastName,
    title: data.title,
    photoUrl: data.photoUrl,
    phoneNo: safePhone,
    deviceType: data.deviceType,
    confirmed: data.confirmed,
    status: data.status,
    specialty: data.specialization,
    docSet: data.docSet,
    requestId: data.requestId,
  };
}
