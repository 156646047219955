import KeyCloakService from './KeyCloakService';
import { AppContext } from '@/config/AppContext';
import { HeadersInit } from 'node-fetch';

export default abstract class BackendAPI {
  protected baseUrl: string;

  constructor() {
    this.baseUrl = AppContext.getApiUrlPath();
  }

  protected async doCallSecured(
    endpoint: string,
    method: string,
    body: any = null,
  ): Promise<Response> {
    const headers = {
      Authorization: KeyCloakService.GetAccessToken()
        ? `Bearer ${KeyCloakService.GetAccessToken()}`
        : '',
    };
    return this.performCall(endpoint, method, body, headers);
  }

  protected async doCallPublic(
    endpoint: string,
    method: string,
    body: any = null,
  ): Promise<Response> {
    const headers = [];
    return this.performCall(endpoint, method, body, headers);
  }

  private async performCall(
    endpoint: string,
    method: string,
    body: any = null,
    additionalHeaders: HeadersInit = {},
  ): Promise<Response> {
    const url = `${this.baseUrl}${endpoint}`;
    const headers: HeadersInit = {
      'Content-Type': 'application/json',
      ...additionalHeaders,
    };
    const response = await fetch(url, {
      method: method,
      headers: headers,
      body: body ? JSON.stringify(body) : null,
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response;
  }
}
