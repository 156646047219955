import { Doctor } from "@/interfaces/Doctor";
import KeyCloakService from "../api/KeyCloakService";
import { convertDoctor, convertToDoctor } from "./pipes/Mapper";
import { DoctorConfig } from "@/interfaces/DoctorConfig";
import { DoctorEntity } from "@/interfaces/DoctorEntity";
import { AppContext } from "@/config/AppContext";

export default {
  async fetchDoctors(): Promise<Doctor[]> {
    try {
      const headers = {
        Authorization: `Bearer ${KeyCloakService.GetAccessToken()}` || "",
      };
      const url = AppContext.getApiUrlPath() + "/doctors";
      const response = await fetch(url, {
        method: "GET",
        headers: headers,
        body: null,
      });
      if (!response.ok) {
        console.error("Unable to fetch doctors:" + response);
        throw new Error("Unable to fetch doctors");
      }
      const data = await response.json();
      const doctors = data.map((apiData: any) => convertToDoctor(apiData));
      return doctors;
    } catch (error) {
      throw new Error("Unable to fetch doctors");
    }
  },
  async saveDoctor(doctor: Doctor): Promise<Doctor> {
    const headers = {
      Authorization: `Bearer ${KeyCloakService.GetAccessToken()}` || "",
      "Content-Type": "application/json",
    };
    const url = AppContext.getApiUrlPath() + "/doctors";
    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(convertDoctor(doctor)),
    });
    const createdDoctor = await response.json();
    if (!response.ok) {
      if (response.status == 409) {
        throw new Error("Numarul de telefon exista deja in baza de date.");
      } else {
        console.error(response);
        throw new Error("A aparut o eroare la salvarea utilizatorului.");
      }
    }
    return createdDoctor;
  },
  async updateDoctor(doctor: Doctor): Promise<Doctor> {
    const headers = {
      Authorization: `Bearer ${KeyCloakService.GetAccessToken()}` || "",
      "Content-Type": "application/json",
    };
    const url = AppContext.getApiUrlPath() + "/doctors/" + doctor.id;
    const response = await fetch(url, {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(convertDoctor(doctor)),
    });
    const createdDoctor = await response.json();
    if (!response.ok) {
      console.error(response);
      throw new Error("A aparut o eroare la actualizarea utilizatorului.");
    }
    return createdDoctor;
  },
  async fetchDoctor(doctorId: string): Promise<Doctor> {
    try {
      const headers = {
        Authorization: `Bearer ${KeyCloakService.GetAccessToken()}` || "",
        "Content-Type": "application/json",
      };
      const url = AppContext.getApiUrlPath() + "/doctors/" + doctorId;
      const response = await fetch(url, {
        method: "GET",
        headers: headers,
        body: null,
      });
      if (!response.ok) {
        console.error("Unable to fetch doctor:");
        console.error(response);
        throw new Error("unable to");
      }
      return convertToDoctor(await response.json());
    } catch (error) {
      throw new Error("Unable to get doctor");
    }
  },
  async suspendDoctor(doctorId: string): Promise<boolean> {
    try {
      const headers = {
        Authorization: `Bearer ${KeyCloakService.GetAccessToken()}` || "",
        "Content-Type": "application/json",
      };
      const url =
        AppContext.getApiUrlPath() + "/doctors/" + doctorId + "/suspend";
      const response = await fetch(url, {
        method: "PATCH",
        headers: headers,
        body: null,
      });
      if (!response.ok) {
        console.error("Unable to suspend doctor:");
        console.error(response);
        throw new Error("unable to");
      }
      return true;
    } catch (error) {
      throw new Error("Unable to get doctor");
    }
  },
  async reactivateDoctor(doctorId: string): Promise<boolean> {
    try {
      const headers = {
        Authorization: `Bearer ${KeyCloakService.GetAccessToken()}` || "",
        "Content-Type": "application/json",
      };
      const url =
        AppContext.getApiUrlPath() + "/doctors/" + doctorId + "/reactivate";
      const response = await fetch(url, {
        method: "PATCH",
        headers: headers,
        body: null,
      });
      if (!response.ok) {
        console.error("Unable to reactivate doctor:");
        console.error(response);
        throw new Error("unable to");
      }
      return true;
    } catch (error) {
      throw new Error("Unable to get doctor");
    }
  },
  async deleteDoctor(doctorId: string): Promise<boolean> {
    try {
      const headers = {
        Authorization: `Bearer ${KeyCloakService.GetAccessToken()}` || "",
        "Content-Type": "application/json",
      };
      const url =
        AppContext.getApiUrlPath() + "/doctors/" + doctorId + "/deactivate";
      const response = await fetch(url, {
        method: "PATCH",
        headers: headers,
        body: null,
      });
      if (!response.ok) {
        console.error("Unable to delete doctor:");
        console.error(response);
        throw new Error("unable to");
      }
      return true;
    } catch (error) {
      throw new Error("Unable to get doctor");
    }
  },
  async fetchDoctorConfig(doctorId: string): Promise<DoctorConfig> {
    try {
      const headers = {
        Authorization: `Bearer ${KeyCloakService.GetAccessToken()}` || "",
        "Content-Type": "application/json",
      };
      const url =
        AppContext.getApiUrlPath() + "/doctors/" + doctorId + "/config";
      const response = await fetch(url, {
        method: "GET",
        headers: headers,
        body: null,
      });
      if (!response.ok) {
        console.error("Unable to save get doctor:");
        console.error(response);
        throw new Error("unable to");
      }
      return await response.json();
    } catch (error) {
      throw new Error("Unable to get doctor");
    }
  },
  async saveDoctorConfig(
    doctorId: string,
    config: DoctorConfig,
  ): Promise<object> {
    try {
      const headers = {
        Authorization: `Bearer ${KeyCloakService.GetAccessToken()}` || "",
        "Content-Type": "application/json",
      };
      const url =
        AppContext.getApiUrlPath() + "/doctors/" + doctorId + "/config";
      const response = await fetch(url, {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(config),
      });
      if (!response.ok) {
        console.error(response);
        throw new Error(
          "A aparut o eroare la salvarea configuratiei medicului.",
        );
      }
      return response;
    } catch (error) {
      throw new Error("A aparut o eroare la salvarea configuratiei medicului.");
    }
  },
  async saveDoctorEntity(
    doctorId: string,
    config: DoctorEntity,
  ): Promise<object> {
    try {
      const headers = {
        Authorization: `Bearer ${KeyCloakService.GetAccessToken()}` || "",
        "Content-Type": "application/json",
      };
      const url =
        AppContext.getApiUrlPath() + "/doctors/" + doctorId + "/entity";
      //alert(url);
      const response = await fetch(url, {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(config),
      });
      if (!response.ok) {
        console.error(response);
        throw new Error("A aparut o eroare la salvarea entitatii medicului.");
      }
      return response;
    } catch (error) {
      throw new Error("A aparut o eroare la salvarea entitatii medicului.");
    }
  },
  async saveDoctorDocument(
    doctorId: string,
    docSetId: any,
    file: File,
  ): Promise<boolean> {
    try {
      const headers = {
        Authorization: `Bearer ${KeyCloakService.GetAccessToken()}` || "",
      };
      const url = AppContext.getApiUrlPath() + "/files/enrollment/doctor";
      const formData = new FormData();
      formData.append("doctorId", doctorId);
      formData.append("docSet", docSetId);
      formData.append("content", file);
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: formData,
      });
      if (!response.ok) {
        console.error(response);
        throw new Error(
          "A aparut o eroare la salvarea documentelor medicului.",
        );
      }
      return true;
    } catch (error) {
      throw new Error("A aparut o eroare la salvarea documentelor medicului.");
    }
  },
  async fetchDoctorDocument(
    doctorId: string,
    docSetId: number,
  ): Promise<{ file: Blob; fileName: string }> {
    try {
      const headers = {
        Authorization: `Bearer ${KeyCloakService.GetAccessToken()}` || "",
      };
      const url =
        AppContext.getApiUrlPath() +
        "/files/enrollment/doctor?doctorId=" +
        doctorId +
        "&docSet=" +
        docSetId;
      const response = await fetch(url, {
        method: "GET",
        headers: headers,
      });
      if (!response.ok) {
        console.error(response);
        throw new Error(
          "A aparut o eroare la salvarea documentelor medicului.",
        );
      }
      const contentDispositionHeader =
        response.headers.get("Content-Disposition") ||
        response.headers.get("content-disposition");
      const blob = await response.blob();
      if (contentDispositionHeader) {
        const filenameRegex = /filename[^;=\n]*=['"]?([^;\n]*)['"]?(;|$)/;
        const matches = filenameRegex.exec(contentDispositionHeader);
        if (matches != null && matches[1]) {
          let serverFilename = matches[1];
          serverFilename = serverFilename.trim().replace(/[_\W]+$/, "");
          serverFilename = serverFilename.replace(/^["']+|["']+$/g, "");
          return {
            file: blob,
            fileName: serverFilename,
          };
        }
      }
      throw new Error(
        "A aparut o eroare la descarcarea documentelor medicului.",
      );
    } catch (error) {
      console.log("EROAREA");
      console.log(error); // de ce nu logheaza????
      throw new Error(
        "A aparut o eroare la descarcare documentelor medicului.",
      );
    }
  },
  async sendDoctorInvitation(doctorId: string): Promise<boolean> {
    try {
      const headers = {
        Authorization: `Bearer ${KeyCloakService.GetAccessToken()}` || "",
        "Content-Type": "application/json",
      };
      const url =
        AppContext.getApiUrlPath() + "/doctors/" + doctorId + "/invite";
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: null,
      });
      if (!response.ok) {
        console.error(response);
        throw new Error("A aparut o eroare la trimiterea invitatiei.");
      }
      return true;
    } catch (error) {
      throw new Error("A aparut o eroare la trimiterea invitatiei.");
    }
  },
};
