import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import DoctorListView from '../views/DoctorListView.vue';
import DoctorDetailsView from '../views/DoctorDetailsView.vue';
import PayoutDetailsView from '../views/PayoutDetailsView.vue';
import RegisterRequestListView from '../views/RegisterRequestListView.vue';
import ConfigurationView from '@/views/ConfigurationView.vue';
import PatientListView from '@/views/PatientListView.vue';
import PayoutListView from '@/views/PayoutListView.vue';
import RequestAccountView from '@/views/RequestAccountView.vue';
import TermsAndConditionsView from '@/views/TermsAndConditionsView.vue';
import PlatformPoliciesView from '@/views/PlatformPoliciesView.vue';
import TermsAndConditionsDoctorView from '@/views/TermsAndConditionsDoctorView.vue';
import PlatformPoliciesDoctorView from '@/views/PlatformPoliciesDoctorView.vue';
import KeyCloakService from '@/api/KeyCloakService';
import AppLayout from '@/layout/AppLayout.vue';
import Dashboard from '@/views/Dashboard.vue';
import ReportsView from '@/views/ReportsView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/doctors',
    name: 'doctors',
    component: DoctorListView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/doctors/:id',
    name: 'doctor-details',
    component: DoctorDetailsView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/patients',
    name: 'patients',
    component: PatientListView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/payouts',
    name: 'payouts',
    component: PayoutListView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/payouts/:id',
    name: 'payout-details',
    component: PayoutDetailsView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/config',
    name: 'config',
    component: ConfigurationView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reports',
    name: 'reports',
    component: ReportsView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/requests',
    name: 'requests',
    component: RegisterRequestListView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reports',
    name: 'reports',
    component: ReportsView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/request-account',
    name: 'request-account',
    component: RequestAccountView,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/terms-and-conditions',
    name: 'terms-and-conditions',
    component: TermsAndConditionsView,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/platform-policies',
    name: 'platform-policies',
    component: PlatformPoliciesView,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/dterms-and-conditions',
    name: 'terms-and-conditions-doctor',
    component: TermsAndConditionsDoctorView,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/dplatform-policies',
    name: 'platform-policies-doctor',
    component: PlatformPoliciesDoctorView,
    meta: {
      requiresAuth: false
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      component: AppLayout,
      children: routes
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (KeyCloakService.IsLogged()) {
      next();
    } else {
      KeyCloakService.CallLogin(() => {
        next();
      });
    }
  } else {
    next();
  }
});

export default router;
