import { DoctorEntity } from "@/interfaces/DoctorEntity";
import BackendAPI from "@/api/BackendAPI";

export default class DoctorEntityService extends BackendAPI {
  constructor() {
    super();
  }

  public async fetchDoctorEntity(doctorId: string): Promise<DoctorEntity> {
    const endpoint = "/doctors/" + doctorId + "/entity";
    const response = await this.doCallSecured(endpoint, "GET");
    return await response.json();
  }
}
