import KeyCloakService from "../api/KeyCloakService";
import { AppContext } from "@/config/AppContext";

export default {
  async fetchPatients(): Promise<object> {
    try {
      const headers = {
        Authorization: `Bearer ${KeyCloakService.GetAccessToken()}` || "",
      };
      const url = AppContext.getApiUrlPath() + "/patients";
      //alert(url);
      const response = await fetch(url, {
        method: "GET",
        headers: headers,
        body: null,
      });
      console.log(response);
      if (!response.ok) {
        console.error("Unable to fetch patients:" + response);
        throw new Error("Unable to fetch patients");
      }
      const data = await response.json();
      //const doctors = data.map((apiData: any) => convertToDoctor(apiData));
      console.log(data);
      return data;
    } catch (error) {
      throw new Error("Unable to fetch patients");
    }
  },
  async fetchPatientsByDoctorId(doctorId: string): Promise<object> {
    try {
      const headers = {
        Authorization: `Bearer ${KeyCloakService.GetAccessToken()}` || "",
      };
      const url =
        AppContext.getApiUrlPath() + "/doctors/" + doctorId + "/patients";
      //alert(url);
      const response = await fetch(url, {
        method: "GET",
        headers: headers,
        body: null,
      });
      console.log(response);
      if (!response.ok) {
        console.error("Unable to fetch patients:" + response);
        throw new Error("Unable to fetch patients");
      }
      const data = await response.json();
      //const doctors = data.map((apiData: any) => convertToDoctor(apiData));
      console.log(data);
      return data;
    } catch (error) {
      throw new Error("Unable to fetch patients");
    }
  },
};
