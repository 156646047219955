
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { ModalAction, useModal } from '@/stores/modal';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';

export default {
  components: {
    Dialog,
    Button
  },
  setup() {
    const modal = useModal();
    const model = ref<any>({});
    const { isOpen, view, actions } = storeToRefs(modal);

    const triggerAction = (action: ModalAction) => {
      action.disabled = true;
      action.callback(model.value);
    };

    return { model, modal, isOpen, view, actions, triggerAction };
  }
};
