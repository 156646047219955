import { markRaw } from "vue";
import { defineStore } from "pinia";

export type Modal = {
  header: string;
  isOpen: boolean;
  view: object;
  actions?: ModalAction[];
  initActions?: ModalAction[];
};

export type ModalAction = {
  icon: string;
  label: string;
  disabled: boolean;
  callback: (props?: any) => void;
};

export const useModal = defineStore("drappmodal", {
  state: (): Modal => ({
    header: "",
    isOpen: false,
    view: {},
    actions: [],
    initActions: [],
  }),
  actions: {
    open(header: string, view: object, actions?: ModalAction[]) {
      this.header = header;
      this.isOpen = true;
      this.actions = actions;
      this.initActions = JSON.parse(JSON.stringify(this.actions)); //CC-TODO deepclone
      this.view = markRaw(view);
    },
    close() {
      this.header = "";
      this.isOpen = false;
      this.view = {};
      this.actions = [];
    },
    refresh() {
      this.actions?.forEach((action) => (action.disabled = false));
    },
    restate() {
      this.actions?.forEach((action) => {
        this.initActions?.forEach((initAction) => {
          if (action.label === initAction.label) {
            action.disabled = initAction.disabled;
          }
        });
      });
    },
  },
});

export default useModal;
