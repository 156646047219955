import KeyCloakService from "../api/KeyCloakService";
import { AppContext } from "@/config/AppContext";
import { RegisterRequest } from "@/interfaces/RegisterRequest";

export default {
  async fetchRegisterRequests(): Promise<object> {
    try {
      const headers = {
        Authorization: `Bearer ${KeyCloakService.GetAccessToken()}` || "",
      };
      const url = AppContext.getApiUrlPath() + "/register_requests";
      //alert(url);
      const response = await fetch(url, {
        method: "GET",
        headers: headers,
        body: null,
      });
      console.log(response);
      if (!response.ok) {
        console.error("Unable to fetch register requests:" + response);
        throw new Error("Unable to fetch register requests");
      }
      const data = await response.json();
      //const doctors = data.map((apiData: any) => convertToDoctor(apiData));
      console.log(data);
      return data;
    } catch (error) {
      throw new Error("Unable to fetch register requests");
    }
  },
  async fetchRegisterRequest(id: string): Promise<RegisterRequest> {
    try {
      const headers = {
        Authorization: `Bearer ${KeyCloakService.GetAccessToken()}` || "",
      };
      const url = AppContext.getApiUrlPath() + "/register_requests/" + id;
      //alert(url);
      const response = await fetch(url, {
        method: "GET",
        headers: headers,
        body: null,
      });
      console.log(response);
      if (!response.ok) {
        console.error("Unable to fetch register requests:" + response);
        throw new Error("Unable to fetch register requests");
      }
      const data = await response.json();
      //const doctors = data.map((apiData: any) => convertToDoctor(apiData));
      console.log(data);
      return data;
    } catch (error) {
      throw new Error("Unable to fetch register requests");
    }
  },
  async saveRegisterRequest(
    registerRequest: RegisterRequest | null,
  ): Promise<RegisterRequest> {
    try {
      const headers = {
        "Content-Type": "application/json",
        //Authorization: `Bearer ${KeyCloakService.GetAccessToken()}` || '',
      };
      const url = AppContext.getApiUrlPath() + "/register_requests";
      //alert(url);
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(registerRequest),
      });
      if (!response.ok) {
        console.error(response);
        throw new Error("A aparut o eroare la salvarea cererii.");
      }
      return response.json();
    } catch (error) {
      throw new Error("A aparut o eroare la salvarea cererii.");
    }
  },
  async rejectRegisterRequest(
    registerRequest: Partial<RegisterRequest> | null,
  ): Promise<RegisterRequest> {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${KeyCloakService.GetAccessToken()}` || "",
      };
      const url =
        AppContext.getApiUrlPath() +
        "/register_requests/" +
        registerRequest?.id +
        "/reject";
      //alert(url);
      const response = await fetch(url, {
        method: "PATCH",
        headers: headers,
        body: JSON.stringify(registerRequest),
      });
      if (!response.ok) {
        console.error(response);
        throw new Error("A aparut o eroare la salvarea cererii.");
      }
      return response.json();
    } catch (error) {
      throw new Error("A aparut o eroare la salvarea cererii.");
    }
  },
};
