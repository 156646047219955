import { TermsAndConditions } from "@/interfaces/TermsAndConditions";
import { PlatformPolicies } from "@/interfaces/PlatformPolicies";
import BackendAPI from "@/api/BackendAPI";

export default class AppConfigService extends BackendAPI {
  constructor() {
    super();
  }

  public async fetchTermsAndConditions(): Promise<TermsAndConditions> {
    const endpoint = "/configurations/termsandconditions";
    const response = await this.doCallPublic(endpoint, "GET");
    return await response.json();
  }

  public async fetchTermsAndConditionsForDoctor(): Promise<TermsAndConditions> {
    const endpoint = "/configurations/termsandconditions/doctor";
    const response = await this.doCallPublic(endpoint, "GET");
    return await response.json();
  }

  public async fetchPlatformPolicies(): Promise<PlatformPolicies> {
    const endpoint = "/configurations/platformpolicies";
    const response = await this.doCallPublic(endpoint, "GET");
    return await response.json();
  }

  public async fetchPlatformPoliciesForDoctor(): Promise<PlatformPolicies> {
    const endpoint = "/configurations/platformpolicies/doctor";
    const response = await this.doCallPublic(endpoint, "GET");
    return await response.json();
  }

  public async updateTermsAndConditions(
    terms: TermsAndConditions,
  ): Promise<boolean> {
    const endpoint = "/configurations/termsandconditions";
    await this.doCallSecured(endpoint, "PUT", terms);
    return true;
  }

  public async updateTermsAndConditionsForDoctor(
    terms: TermsAndConditions,
  ): Promise<boolean> {
    const endpoint = "/configurations/termsandconditions/doctor";
    await this.doCallSecured(endpoint, "PUT", terms);
    return true;
  }

  public async updatePlatformPolicies(
    policies: PlatformPolicies,
  ): Promise<boolean> {
    const endpoint = "/configurations/platformpolicies";
    await this.doCallSecured(endpoint, "PUT", policies);
    return true;
  }

  public async updatePlatformPoliciesForDoctor(
    policies: PlatformPolicies,
  ): Promise<boolean> {
    const endpoint = "/configurations/platformpolicies/doctor";
    await this.doCallSecured(endpoint, "PUT", policies);
    return true;
  }
}
