<script setup>
import { ref } from 'vue';

import AppMenuItem from './AppMenuItem.vue';

const model = ref([
  {
    label: 'Acasă',
    items: [
      { label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/' },
      { label: 'Medici', icon: 'pi pi-user', to: '/doctors' },
      { label: 'Pacienți', icon: 'pi pi-book', to: '/patients' },
      { label: 'Decontări', icon: 'pi pi-dollar', to: '/payouts' },
      { label: 'Înrolări', icon: 'pi pi-user-plus', to: '/requests' },
      { label: 'Configurări', icon: 'pi pi-cog', to: '/config' },
      { label: 'Rapoarte', icon: 'pi pi-chart-bar', to: '/reports' },
    ],
  },
]);
</script>

<template>
  <ul class="layout-menu">
    <template v-for="(item, i) in model" :key="item">
      <app-menu-item
        v-if="!item.separator"
        :item="item"
        :index="i"
      ></app-menu-item>
      <li v-if="item.separator" class="menu-separator"></li>
    </template>
  </ul>
</template>

<style lang="scss" scoped></style>
