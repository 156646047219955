export enum Device {
  ANDROID = "ANDROID",
  IPHONE = "IPHONE",
  HUAWEI = "HUAWEI",
}

export const DeviceTypeInfo = {
  [Device.ANDROID]: { icon: "pi pi-android" },
  [Device.IPHONE]: { icon: "pi pi-apple" },
  [Device.HUAWEI]: { icon: "pi pi-slack" },
};

export interface DeviceType {
  name: Device;
  icon: string;
}

export class DeviceSerializer {
  static deserialize(deviceString: string): DeviceType | undefined {
    const device = Device[deviceString as keyof typeof Device];
    if (!device) {
      return undefined;
    } else {
      return {
        name: device,
        icon: DeviceTypeInfo[device].icon,
      } as DeviceType;
    }
  }
  static serialize(deviceType: DeviceType): string | undefined {
    return Device[deviceType.name as keyof typeof Device] || undefined;
  }
}
